import { Controller } from "stimulus";
import { debounce, getCSRFToken, setUpdatedCsrfMetaContent, throttle } from "../../util"
import {
  EventPriceLoaded,
  EventTooltipLoaded,
  EventFocusSearchGlobal,
  EventTogglePortfolioSummary,
  EventViewPortfolioSummaryCard,
  EventHidePortfolioSummaryCard, EventMixpanelInitLoaded
} from "../../events";
import Cookies from "js-cookie";
import { trackEvent, unconditionalTrackEvent } from "../../analytics";
import {
  SELECT_COIN_SENTIMENT_VOTE_EVENT,
  SELECT_PORTFOLIO_SUMMARY_CARD_TOGGLE,
  VIEW_COIN_INSIGHTS,
  VIEW_COIN_RECOMMENDATION,
  SELECT_COIN_CONTENT_FEEDBACK_EVENT
} from "../../mixpanel_config";
import { elementIsVisibleInViewport } from "../../helpers/viewport";

const SHOW_PSC_COOKIE = "coin_show:show_portfolio_summary_card";

const VALID_FEEDBACK_TYPES = ["positive", "negative"];
const CONTENT_FEEDBACK_COOKIE = (coin_id) => `coin_show:${coin_id}:content_feedback`;
const CONTENT_FEEDBACK_SURVEY_URL = "https://docs.google.com/forms/d/e/1FAIpQLSfN_a8oWdrkzbpOGTRAicLDVaX5Fhu9DxZflDGeYVF0FVh7yg/viewform";
const CONTENT_FEEDBACK_PREFILL_PARAM = "entry.1492515896"

export default class extends Controller {
  static targets = [
    "contentRow", "contentContainer",

    // Sticky price
    "staticCoinPrice", "stickyCoinPrice", "stickyCoinPriceCta",

    // Navigation tabs
    "coinTabs", "navAnchor", "securityContent", "tokenomicsContent",

    // Coin markets
    "markets", "marketsLoading",

    // Sentiment votes
    "positiveSentiment", "negativeSentiment",

    // Floating action button
    "portfolioButton", "floatingActionButton",

    // Portfolio summary card
    "portfolioSummaryCard", "portfolioSummaryCardContainer",
    "portfolioSummaryCardButton", "portfolioSummaryCardButtonContainer",

    // Recommended + trending coins
    "recommendedCoinsContainer", "trendingCoinsContainer",

    // Content feedback container
    "contentFeedbackResult", "contentFeedbackButtons"
  ];

  static values = {
    coinApiSymbol: String
  }

  connect() {
    this.coinApiSymbol = this.element.dataset.coinApiSymbol;
    this.coinId = this.element.dataset.coinId;
    this.hasSentViewRecommendedCoinsEvent = false;
    this.hasSentViewTrendingCoinsEvent = false;

    this._setupSentimentVotes();
    this._setupPortfolioSummaryComponents();
    this._setupPortfolioSummaryChangeListener();
    this._setupContentFeedback();
    this._handleStickyNavigation();
    this._handleNavigationHighlight();
    this._handleFloatingButton();

    window.addEventListener("scroll", throttle(this._handleNavigationHighlight.bind(this), 100));
    window.addEventListener("scroll", throttle(this._handleFloatingButton.bind(this), 50));
    window.addEventListener("resize", throttle(this._resizeContentContainer.bind(this), 25));

    // Use both debounce/throttle to fire the event one more time after the last event.
    // This prevents the navbar from sticking to the top when scrolling up too fast.
    const debouncedScrollEventHandler = debounce(this._handleStickyNavigation.bind(this), 80);
    const throttledScrollEventHandler = throttle(debouncedScrollEventHandler.bind(this), 75);
    window.addEventListener("scroll", throttledScrollEventHandler);

    this._resizeContentContainer();

    if (window.location.hash.substring(1) === "security" && this.hasSecurityContentTarget) {
      this.securityContentTarget.scrollIntoView();
      this.loadSecurityContent();
    }

    if (window.location.hash.substring(1) === "tokenomics" && this.hasTokenomicsContentTarget) {
      // Check for onclick handler; it contains the "no tokenomics content" popup message.
      const tokenomicsButtonTarget = document.querySelector("#tab-tokenomics");
      if (tokenomicsButtonTarget && tokenomicsButtonTarget.onclick) {
        tokenomicsButtonTarget.onclick();
      }

      this.tokenomicsContentTarget.scrollIntoView();
      this.loadTokenomicsContent();
    }

    if (window.location.hash.substring(1) === "markets" && this.hasMarketsTarget) {
      this._scrollToAnchor("tab-markets")
    }

    window.addEventListener(EventMixpanelInitLoaded, (e) => {
      this._detectRecommendedCoinsInViewport();
      window.addEventListener("scroll", this._detectRecommendedCoinsInViewport.bind(this));

      this._detectTrendingCoinsInViewport()
      window.addEventListener("scroll", this._detectTrendingCoinsInViewport.bind(this));
    });
  }

  _resizeContentContainer() {
    if (!this.hasContentContainerTarget) {
      return;
    }

    if (window.innerWidth < 1200) {
      this.contentContainerTarget.style.maxHeight = null;
      return;
    }

    const offset = 120;
    const height = this.contentRowTarget.getBoundingClientRect().height - offset;

    this.contentContainerTarget.style.maxHeight = height + "px";
  }

  _handleStickyNavigation() {
    if (this.staticCoinPriceTarget.getBoundingClientRect().top >= -20) {
      this.stickyCoinPriceTarget.classList.add("tw-hidden");
      this.stickyCoinPriceCtaTarget.classList.remove("2lg:tw-flex");
    } else {
      this.stickyCoinPriceTarget.classList.remove("tw-hidden");
      this.stickyCoinPriceCtaTarget.classList.add("2lg:tw-flex");
    }
  }

  _handleFloatingButton() {
    if (this.portfolioButtonTarget.getBoundingClientRect().top >= -20) {
      this.floatingActionButtonTarget.classList.remove("tw-flex");
      this.floatingActionButtonTarget.classList.add("tw-hidden");
    } else {
      this.floatingActionButtonTarget.classList.remove("tw-hidden");
      this.floatingActionButtonTarget.classList.add("tw-flex");
    }
  }

  _handleNavigationHighlight() {
    let previousElement = this.coinTabsTarget.querySelector(".selected");
    if (previousElement) {
      previousElement.classList.remove("selected");
    }

    // Prevents the first item from being unhighlighted before meeting scroll threshold.
    if (window.scrollY <= 300) {
      const firstAnchor = this.navAnchorTargets[0].dataset.key;
      this.coinTabsTarget.querySelector(`#${firstAnchor}`)?.classList?.add("selected");
      return;
    }

    let nearestElement = this.navAnchorTargets.filter(x => x.offsetParent !== null && x.getBoundingClientRect().top <= 200).reverse()[0];
    if (nearestElement) {
      this.coinTabsTarget.querySelector(`#${nearestElement.dataset.key}`)?.classList?.add("selected");
    }
  }

  scrollToAnchor(e) {
    return this._scrollToAnchor(e.currentTarget.id);
  }

  _scrollToAnchor(anchorId) {
    let target = this.navAnchorTargets.filter(x => x.dataset.key === anchorId)[0];
    if (!target) {
      return;
    }

    const top = target.getBoundingClientRect().top + window.scrollY - 75;
    window.scrollTo({ top, behavior: "smooth" });
  }


  async handleVote(e) {
    if (this.hasVotedToday) {
      Toaster.toast(I18n.t("sentiment_votes.already_voted"));
      return;
    }

    const url = e.currentTarget.dataset.url;

    await setUpdatedCsrfMetaContent();
    fetch(url, { method: "POST", headers: { "X-CSRF-Token": getCSRFToken() }, credentials: "same-origin" })
      .then(response => response.json())
      .then(data => {
        this.hasVotedToday = true;

        if (!data.success) {
          Toaster.toast(I18n.t("sentiment_votes.already_voted"));
        } else {
          this._updateSentimentVotesPercentage(data.percentage);
          this._trackSentimentVoteEvent(url)
        }
      });
  }

  togglePortfolioSummaryCard() {
    this.portfolioSummaryCardTarget.classList.toggle("tw-hidden");
    this.portfolioSummaryCardButtonTarget.classList.toggle("gecko-button-dropdown-secondary");
    this.portfolioSummaryCardButtonTarget.classList.toggle("gecko-button-dropdown-open");

    let eventName = ""

    if(this.portfolioSummaryCardTarget.classList.contains("tw-hidden")) {
      this.portfolioSummaryCardButtonTarget.dataset.tooltip = I18n.t("coins.show.portfolio_summary.show_portfolio");
      eventName = EventHidePortfolioSummaryCard;
      Cookies.set(SHOW_PSC_COOKIE, false);
      this._mixpanelTrackEvent(this.coinApiSymbolValue, false)
    } else {
      this.portfolioSummaryCardButtonTarget.dataset.tooltip = I18n.t("coins.show.portfolio_summary.hide_portfolio");
      eventName = EventViewPortfolioSummaryCard;
      Cookies.set(SHOW_PSC_COOKIE, true);
      this._mixpanelTrackEvent(this.coinApiSymbolValue, true)
    }

    this._trackEvent(eventName);
  }

  _setupPortfolioSummaryComponents() {
    this.showPortfolioSummaryCard = Cookies.get(SHOW_PSC_COOKIE) !== "false";
    this._fetchPortfolioSummaryButton();
    this._fetchPortfolioSummaryCard();
  }

  _fetchPortfolioSummaryButton() {
    fetch(`${this.coinId}/portfolio_summary_button`)
      .then(async response => {
        if (!response.ok) {
          return Promise.reject("Could not fetch portfolio summary button.");
        }

        const html = await response.text();
        if (html !== '') {
          this.portfolioSummaryCardButtonContainerTarget.innerHTML = html;
          this.portfolioSummaryCardButtonContainerTarget.classList.remove("tw-hidden");

          if (this.showPortfolioSummaryCard) {
            this.portfolioSummaryCardButtonTarget.classList.add("gecko-button-dropdown-secondary", "gecko-button-dropdown-open");
            this.portfolioSummaryCardButtonTarget.dataset.tooltip = I18n.t("coins.show.portfolio_summary.hide_portfolio");
          } else {
            this.portfolioSummaryCardButtonTarget.dataset.tooltip = I18n.t("coins.show.portfolio_summary.show_portfolio");
          }
        }
      });
  }

  _fetchPortfolioSummaryCard() {
    fetch(`${this.coinId}/portfolio_summary_card`)
      .then(async response => {
        if (!response.ok) {
          return Promise.reject("Could not fetch portfolio summary card.");
        }

        const html = await response.text();
        if (html !== '') {
          this.portfolioSummaryCardContainerTarget.innerHTML = html;
          [this.portfolioSummaryCardButtonContainerTarget, this.portfolioSummaryCardContainerTarget].forEach(el => {
            el.classList.remove("tw-hidden");
            el.dispatchEvent(new CustomEvent(EventTooltipLoaded, { bubbles: true }));
          });

          if (this.showPortfolioSummaryCard) {
            this.portfolioSummaryCardTarget.classList.remove("tw-hidden");
            this._trackEvent(EventViewPortfolioSummaryCard);
          }
        }
      });
  }

  _setupPortfolioSummaryChangeListener(e) {
    window.addEventListener(EventTogglePortfolioSummary, (e) => {
      let showSummary = e.detail.show;

      if (showSummary) {
        // to prevent firing GA event when the card is already visible
        if (this.portfolioSummaryCardButtonContainerTarget.classList.contains("tw-hidden")) {
          this._setupPortfolioSummaryComponents();
        }
      } else {
        this._hidePortfolioSummaryComponents(e.detail.trackGAEvent);
      }
    });
  }

  _hidePortfolioSummaryComponents(trackGAEvent = true) {
    this.portfolioSummaryCardButtonContainerTarget.classList.add("tw-hidden");
    this.portfolioSummaryCardContainerTarget.classList.add("tw-hidden");

    if (trackGAEvent) {
      this._trackEvent(EventHidePortfolioSummaryCard);
    }
  }

  _setupSentimentVotes() {
    if (!this.hasPositiveSentimentTarget || !this.hasNegativeSentimentTarget) {
      return;
    }

    // Prepare sentiment votes functionality.
    import("@lottiefiles/lottie-player");

    // Check for existing vote to enable/disable voting.
    fetch(`/sentiment_votes/voted_coin_today?api_symbol=${this.coinApiSymbol}`)
      .then(response => response.json())
      .then(voted => {
        this.hasVotedToday = !!voted.id;
        this._updateSentimentVotesPercentage(voted.percentage);
      });
  }

  _updateSentimentVotesPercentage(percentage) {
    if (percentage) {
      const positive = Math.round(percentage.positive || 0)
      const negative = Math.round(percentage.negative || 0)

      this.positiveSentimentTarget.innerText = `${positive}%`;
      this.negativeSentimentTarget.innerText = `${negative}%`;
    }
  }

  _trackSentimentVoteEvent(url) {
    if (url.includes("sentiment=positive")) {
      trackEvent(SELECT_COIN_SENTIMENT_VOTE_EVENT, { vote_type: "bullish" });
    } else if (url.includes("sentiment=negative")) {
      trackEvent(SELECT_COIN_SENTIMENT_VOTE_EVENT, { vote_type: "bearish" });
    }
  }

  reloadMarkets(e) {
    if (this.isMarketsLoading === true) {
      return;
    }

    if (this.hasMarketsLoadingTarget) {
      this.marketsLoadingTarget.classList.remove("!tw-hidden");
    }

    this.isMarketsLoading = true;

    fetch(e.currentTarget.dataset.url)
      .then(response => response.text())
      .then(html => {
        this.marketsTarget.innerHTML = html;
        this.marketsTarget.dispatchEvent(new CustomEvent(EventPriceLoaded, { bubbles: true }));
        this.marketsTarget.dispatchEvent(new CustomEvent(EventTooltipLoaded, { bubbles: true }));
      })
      .finally(() => this.isMarketsLoading = false);
  }


  loadTokenomicsContent() {
    if (!this.hasTokenomicsContentTarget || this.tokenomicsContentLoaded) {
      return;
    }

    fetch(this.tokenomicsContentTarget.dataset.contentUrl)
      .then(response => response.text())
      .then(html => {
        this.tokenomicsContentTarget.innerHTML = html;
        this.tokenomicsContentLoaded = true;
      });
  }

  loadSecurityContent() {
    if (!this.hasSecurityContentTarget || this.securityContentLoaded) {
      return;
    }

    fetch(this.securityContentTarget.dataset.contentUrl)
      .then(response => response.text())
      .then(html => {
        this.securityContentTarget.innerHTML = html;
        this.securityContentLoaded = true;
      });
  }

  openSearchPopover() {
    window.dispatchEvent(
      new CustomEvent(EventFocusSearchGlobal, {
        detail: {
          fromStickyCta: true
        }
      })
    );
  }

  _trackEvent(eventName) {
    if (this.element.dataset.page !== "coins" || typeof gtag === 'undefined') {
      return;
    }

    gtag("event", eventName, {
      "event_url": window.location.pathname,
    });
  }

  _detectRecommendedCoinsInViewport() {
    if (this.hasRecommendedCoinsContainerTarget && elementIsVisibleInViewport(this.recommendedCoinsContainerTarget)) {
      if (!this.hasSentViewRecommendedCoinsEvent) {
        trackEvent(VIEW_COIN_RECOMMENDATION, { coin_name: "related_coins" })
        this.hasSentViewRecommendedCoinsEvent = true
      }
    }
  }

  _detectTrendingCoinsInViewport() {
    if (elementIsVisibleInViewport(this.trendingCoinsContainerTarget)) {
      if (!this.hasSentViewTrendingCoinsEvent) {
        trackEvent(VIEW_COIN_RECOMMENDATION, { coin_name: "trending_coins" })
        this.hasSentViewTrendingCoinsEvent = true
      }
    }
  }

  _mixpanelTrackEvent(coinApiSymbol, visible) {
    trackEvent(SELECT_PORTFOLIO_SUMMARY_CARD_TOGGLE, { coin_name: coinApiSymbol, visible });
  }

  handleContentFeedback(e) {
    const feedbackType = e.currentTarget.dataset.feedback
    const feedbackCookie = CONTENT_FEEDBACK_COOKIE(this.coinId);

    if (!VALID_FEEDBACK_TYPES.includes(feedbackType) || Cookies.get(feedbackCookie)) {
      return;
    }

    Cookies.set(feedbackCookie, feedbackType);
    unconditionalTrackEvent(SELECT_COIN_CONTENT_FEEDBACK_EVENT, { feedback_type: feedbackType, coin_name: this.coinApiSymbolValue })
    this._setupContentFeedback();
  }

  _setupContentFeedback() {
    const feedbackCookie = CONTENT_FEEDBACK_COOKIE(this.coinId);
    const feedbackType = Cookies.get(feedbackCookie);
    if (!feedbackType || !this.hasContentFeedbackResultTarget || !this.hasContentFeedbackButtonsTarget) {
      return;
    }

    if (!VALID_FEEDBACK_TYPES.includes(feedbackType)) {
      Cookies.remove(feedbackCookie);
      return;
    }

    const feedbackUrl = new URL(CONTENT_FEEDBACK_SURVEY_URL);
    feedbackUrl.searchParams.set(CONTENT_FEEDBACK_PREFILL_PARAM, window.location.href);

    this.contentFeedbackResultTarget.innerHTML = I18n.t(`coins.show.content_feedback_result.${feedbackType}`, {survey_url: feedbackUrl.toString()});
    this.contentFeedbackButtonsTarget.remove();
  }
}
