import Cookies from "js-cookie";

export function syncCookieAndLocalStorage(key) {
  if (localStorage.getItem(key) !== null) return;

  localStorage.setItem(key, Cookies.get(key));
}

export function setCookieAndLocalStorage(key, value, expirationDays = 365) {
  localStorage.setItem(key, value);
  Cookies.set(key, value, { expires: expirationDays });
}

export function getCookieAndLocalStorage(key) {
  return localStorage.getItem(key) || Cookies.get(key);
}
